import React from "react"
import { graphql } from "gatsby"
import localize from "../components/localize"
import Home from "../components/home"

const PageInfo = ({ pathname, langKey, data, onClick }) => {
  const { sanityExhibition } = data

  return (
    <Home
      pathname={pathname}
      langKey={langKey}
      exName={sanityExhibition.exName}
      onClick={index => onClick(index)}
    />
  )
}

export default localize(PageInfo)

export const query = graphql`
  query ($id: String!) {
    sanityExhibition(id: { eq: $id }) {
      ...SanityExhibitionFragment
    }
    allSanityExhibition(sort: { fields: exName, order: ASC }) {
      ...SanityExhibitionConnectionFragment
    }
    allSanityArtist(
      filter: { participance: { eq: true }, exhibition: { id: { eq: $id } } }
      sort: { fields: lastName, order: ASC }
    ) {
      ...SanityArtistConnectionFragment
    }
    sanitySiteSettings {
      ...SanitySiteSettingsFragment
    }
  }
`
